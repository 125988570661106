import React from 'react'
import { graphql } from 'gatsby'
import styled from 'styled-components'
import tw from 'twin.macro'
import SEO from '../components/SEO/seo'
import SliceZone from '../components/SliceZone'


export default ({ data }) => {
  if (!data) return null
  const doc = data.prismic.allWines.edges.slice(0, 1).pop()
  const slices = doc.node.body
  const title = doc.node.productname[0].text

  return (
    <>
      <SEO title={title} />
      <Root>
        <SliceZone slices={slices} />
      </Root>
    </>
  )
}

const Root = styled.div`
    ${tw`sm:mx-2 md:mx-12 mb-20`}
    min-height: calc(100vh - 230px);

    & h2 {
        ${tw`py-2`}
        font-size: 2rem;
        font-family: 'Futura Medium'
    }

`;

export const query = graphql`
query WineQuery($uid: String ,$lang: String) {
  prismic {
    allWines(uid: $uid, lang: $lang) {
      edges {
        node {
          body {
            ... on PRISMIC_WineBodySideimage_with_text {
              type
              label
              primary {
                content
                image1
              }
            }
             ... on PRISMIC_WineBodyImage_with_text {
              type
              label
              primary {
                image
                text
              }
            }
          }
          _meta {
            alternateLanguages {
              lang
              type
              uid
            }
            lang
            type
            uid
          }
          productname
        }
      }
    }
  }
}
`;
